export default {
  account: {
    complete_registration: 'Complete your registration.',
    log_out: 'Log Out',
    welcome: 'Welcome',
  },
  donation_payment: {
    thanks: 'Thanks for your donation.',
    do_first_payment:
      'You can now complete your donation. Do you want to automatically receive the new flag at home first every year? Choose the annual donation with direct debit. We will now collect from the account you are using for payment.',
    optionally_once:
      'If you do not want to authorize Marrekrite for automatic debit, choose: Donate once.',
    do_pay: 'Annual donation with direct debit',
    do_pay_once: 'Donate once',
    reload: 'Check payment',
    already_paid: 'Already paid?',
  },
  donor_account: {
    payment_failed:
      'We could not generate a payment link for you. Please try again later.',
    paid_notice: 'We have successfully processed your donation.',
    saved: 'Preferences saved',
    max_one_product:
      'You can choose a maximum of 1 product. <br>You can order additional items in the webshop.',
    product_explanation:
      'Choose the product you want to receive with your donation.',
    total_amount: 'Total amount: { amount }',
    donation_details:
      'You chose <strong>{ product }</strong>, with a donation of <strong>{ amount }</strong>.',
  },
  generic: {
    amount: 'Quantity',
    fail_message: 'Something went wrong.',
    log_in: 'Log In',
    minlength: 'Minimum { length } characters',
    or: 'or',
    save: 'Save',
    total: 'Total',
  },
  home: {
    sign_up: 'Sign Up',
    account: 'Account',
    new_order: 'New Order',
  },
  login_form: {
    button: 'Log In',
    success: 'We have emailed you further instructions.',
    terms:
      'By submitting your email address you agree to our <a href="https://marrekrite.frl/privacybeleid" class="underline" target="_blank">privacy policy</a>.',
  },
  new_person: {
    button: 'Sign Up',
  },
  order_details: {
    product: 'Product',
  },
  order_form: {
    button: 'Order',
    personal_order: 'Personal order',
    store_order: 'Business order',
    no_lines: 'No products have been added yet.',
    add_line: 'Add Product',
    total: 'Total amount: {amount}',
  },
  people: {
    country: 'Country',
    email: 'Email address',
    postal_code: 'Postal Code',
    house_number: 'House Number',
    house_number_suffix: 'Addition',
    first_name: 'First Name',
    last_name: 'Last Name',
    street: 'Street',
    locality: 'City',
    phone_number: 'Phone Number',
    min_donation_amount: 'The donation must be at least {amount}',
    donation_amount: 'Donation Amount',
    donation_product: 'Product',
    kinds: {
      donor: 'donor',
      store: 'store',
    },
  },
  shop: {
    title: 'Webshop',
    thanks: 'Thanks for your order',
    order_received: 'We have received your order in good order.',
    log_in_first: 'You must log in before placing an order.',
    button: 'Order',
    paid: 'Your order has been successfully paid.',
    not_paid: 'We have not yet registered the payment ({ state }).',
    check_again: 'Check again',
  },
  sign_up: {
    title: 'Sign Up as',
    welcome:
      'Welcome to Recreatieschap Marrekrite, thank you for wanting to become a donor. Follow the steps below.',
    steps: {
      1: 'Enter your email address.',
      2: 'Click on the link we email you.',
      3: 'Enter your personal information.',
      4: 'Choose the amount of your donation and the desired product.',
    },
    login: 'Next',
  },
  store_orders: {
    title: 'Orders',
    reload: 'Reload',
  },
}
