<template>
  <main>
    <div class="grid gap-4 sm:grid-cols-2">
      <router-link
        :to="{ name: 'signup' }"
        class="bg-sky-600 p-6 text-center text-xl text-white"
      >
        {{ $t('home.sign_up') }}
      </router-link>

      <router-link
        :to="{ name: 'account' }"
        class="bg-pink-900 p-6 text-center text-xl text-white"
      >
        {{ $t('home.account') }}
      </router-link>

      <router-link
        :to="{ name: 'new-order' }"
        class="col-span-full bg-neutral-200 p-6 text-center text-xl"
      >
        {{ $t('home.new_order') }}
      </router-link>
    </div>
  </main>
</template>
