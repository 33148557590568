import * as Sentry from '@sentry/vue'

import './style.css'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import router from './router'

import nl from './messages/nl.js'
import de from './messages/de.js'
import en from './messages/en.js'

const app = createApp(App)
const i18n = createI18n({
  legacy: false,
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: {
    nl,
    de,
    en,
  },
})

Sentry.init({
  app,
  environment: import.meta.env.PROD ? 'production' : 'development',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: [
        '/api/donations/create_payment',
        '/api/people/create',
      ],
    }),
  ],
  tracesSampleRate: 0.25,
  tracePropagationTargets: ['localhost', 'https://account.marrekrite.frl/'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
})

const countries = {
  be: 'België/Belgique',
  cz: 'Česká Republika',
  de: 'Deutschland',
  ee: 'Eesti',
  ie: 'Éire',
  gr: 'Ελλάδα (Elláda)',
  es: 'España',
  fr: 'France',
  hr: 'Hrvatska',
  it: 'Italia',
  cy: 'Κύπρος (Kýpros)',
  lt: 'Lietuva',
  lu: 'Luxembourg',
  lv: 'Latvija',
  mt: 'Malta',
  nl: 'Nederland',
  no: 'Norge',
  at: 'Österreich',
  po: 'Polska',
  pt: 'Portugal',
  si: 'Slovenija',
  sk: 'Slovensko',
  ch: 'Suisse',
  fi: 'Suomi',
  se: 'Sverige',
  uk: 'United Kingdom',
  us: 'United States',
}

const months = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
]

app
  .use(router as any)
  .use(i18n as any)
  .provide('appVersion', __APP_VERSION__)
  .provide('countries', countries)
  .provide('months', months)
  .mount('#app')
