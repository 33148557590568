<template>
  <select v-model="selectedLocale">
    <option v-for="l in availableLocales" :value="l">
      {{ titles[l] }}
    </option>
  </select>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  const { availableLocales, locale } = useI18n()

  const selectedLocale = ref(locale.value)

  const titles = {
    nl: 'Nederlands',
    de: 'Deutsch',
    en: 'English',
  } as { [key: string]: string }

  const updateLocale = (l: string) => {
    locale.value = l
    window.localStorage?.setItem('locale', l)
  }

  const setInitialLocale = () => {
    const initialLocale = window.localStorage?.getItem('locale')

    if (initialLocale) {
      selectedLocale.value = initialLocale
    }
  }

  watch(selectedLocale, updateLocale)
  setInitialLocale()
</script>
