export default {
  account: {
    complete_registration: 'Vervollständigen Sie Ihre Registrierung.',
    log_out: 'Abmelden',
    welcome: 'Willkommen',
  },
  donation_payment: {
    thanks: 'Vielen Dank für Ihre Spende.',
    do_first_payment:
      'Sie können Ihre Spende jetzt abschließen. Möchten Sie die neue Flagge automatisch als Erster jedes Jahr nach Hause erhalten? Wählen Sie dann die jährliche Spende mit Einzug. Wir werden zukünftig von dem Konto abbuchen, mit dem Sie jetzt zahlen.',
    optionally_once:
      'Wenn Sie Marrekrite nicht zur automatischen Lastschrift ermächtigen möchten, wählen Sie: Einmalig spenden.',
    do_pay: 'Jährliche Spende mit Einzug',
    do_pay_once: 'Einmalig spenden',
    reload: 'Zahlung überprüfen',
    already_paid: 'Bereits bezahlt?',
  },
  donor_account: {
    payment_failed:
      'Wir konnten keinen Zahlungslink für Sie erstellen. Versuchen Sie es später erneut.',
    paid_notice: 'Wir haben Ihre Spende erfolgreich verarbeitet.',
    saved: 'Einstellungen gespeichert',
    max_one_product:
      'Sie können maximal 1 Produkt auswählen.<br>In unserem Online-Shop können Sie weitere Artikel hinzufügen.',
    product_explanation:
      'Wählen Sie das Produkt, das Sie bei Ihrer Spende erhalten möchten.',
    total_amount: 'Gesamtbetrag: { amount }',
    donation_details:
      'Sie haben sich für <strong>{ product }</strong> entschieden, mit einer Spende von <strong>{ amount }</strong>.',
  },
  generic: {
    amount: 'Menge',
    fail_message: 'Etwas ist schiefgegangen.',
    log_in: 'Anmelden',
    minlength: 'Mindestens { length } Zeichen',
    or: 'oder',
    save: 'Speichern',
    total: 'Gesamt',
  },
  home: {
    sign_up: 'Anmelden',
    account: 'Konto',
    new_order: 'Neue Bestellung',
  },
  login_form: {
    button: 'Anmelden',
    success: 'Wir haben Ihnen weitere Anweisungen per E-Mail gesendet.',
    terms:
      'Mit der Übermittlung Ihrer E-Mail-Adresse stimmen Sie unserer <a href="https://marrekrite.frl/privacybeleid" class="underline" target="_blank">Datenschutzerklärung</a> zu.',
  },
  new_person: {
    button: 'Anmelden',
  },
  order_details: {
    product: 'Produkt',
  },
  order_form: {
    button: 'Bestellen',
    personal_order: 'Persönliche Bestellung',
    store_order: 'Geschäftliche Bestellung',
    no_lines: 'Es wurden noch keine Produkte hinzugefügt.',
    add_line: 'Produkt hinzufügen',
    total: 'Gesamtbetrag: {amount}',
  },
  people: {
    country: 'Land',
    email: 'E-Mail-Adresse',
    postal_code: 'Postleitzahl',
    house_number: 'Hausnummer',
    house_number_suffix: 'Zusatz',
    first_name: 'Vorname',
    last_name: 'Nachname',
    street: 'Straße',
    locality: 'Wohnort',
    phone_number: 'Telefonnummer',
    min_donation_amount: 'Die Spende muss mindestens {amount} betragen',
    donation_amount: 'Spendenbetrag',
    donation_product: 'Produkt',
    kinds: {
      donor: 'Spender',
      store: 'Geschäft',
    },
  },
  shop: {
    title: 'Online-Shop',
    thanks: 'Vielen Dank für Ihre Bestellung',
    order_received: 'Wir haben Ihre Bestellung erhalten.',
    log_in_first:
      'Bevor Sie eine Bestellung aufgeben können, müssen Sie sich zuerst anmelden.',
    button: 'Bestellen',
    paid: 'Ihre Bestellung wurde erfolgreich bezahlt.',
    not_paid: 'Wir haben die Zahlung noch nicht registriert ({ state }).',
    check_again: 'Erneut überprüfen',
  },
  sign_up: {
    title: 'Anmelden als',
    welcome:
      'Willkommen bei Recreatieschap Marrekrite, schön, dass Sie Spender werden möchten. Hier sind die nächsten Schritte.',
    steps: {
      1: 'Geben Sie Ihre E-Mail-Adresse ein.',
      2: 'Klicken Sie auf den Link, den wir Ihnen per E-Mail senden.',
      3: 'Geben Sie Ihre persönlichen Daten ein.',
      4: 'Wählen Sie die Höhe Ihrer Spende und das gewünschte Produkt aus.',
    },
    login: 'Weiter',
  },
  store_orders: {
    title: 'Bestellungen',
    reload: 'Aktualisieren',
  },
}
